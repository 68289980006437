import axios from 'axios'

const request = axios.create({
  baseURL: process.env.VUE_APP_DADATA_SUGGESTION_URL,
  headers: {
    'Authorization': `Token ${process.env.VUE_APP_DADATA_TOKEN}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
})

request.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      return response.data.suggestions;
    }
    else {
      return Promise.reject(response.data)
    }
  })

export function bikSuggestions (query) {
  return new request({
    url: 'bank',
    method: 'POST',
    data: {
      query: query
    }
  })
}

export function companySuggestions (query) {
  return request({
    url: 'party',
    method: 'POST',
    data: {
      query: query,
      status: ["ACTIVE"],
      type: "LEGAL"
    }
  })
}

export function entrepreneurSuggestions (query) {
  return request({
    url: 'party',
    method: 'POST',
    data: {
      query: query,
      status: ["ACTIVE"],
      type: "INDIVIDUAL"
    }
  })
}

export function checkSelfEmployment (data) {
  return axios({
    url: 'https://statusnpd.nalog.ru/api/v1/tracker/taxpayer_status',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    data: data
  })
}
