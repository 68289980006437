<template>
  <v-autocomplete
    outlined
    v-model="select"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    :label="label"
    :error-messages="errorMessages"
    item-text="label"
    item-value="id"
    :placeholder="label"
    :required="required"
    @change="onChange"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          {{
            search ? "такой организации не существует или она не является действующей" : "введите ИНН или название организации для поиска"
          }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
        <v-list-item-subtitle>ИНН: {{ item.id }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>

import {companySuggestions, entrepreneurSuggestions} from "@/http/dadata"
import {companyTypes} from "@/config/constants"

export default {
  name: 'CdCompanySuggest',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: 'ваша фирма'
    },
    suggestType: {
      type: String,
      default: companyTypes.COMPANY
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  data() {
    return {
      select: null,
      items: [],
      search: null,
      isLoading: false
    }
  },
  mounted() {
    this.setValue(this.value)
  },
  watch: {
    value(val) {
      this.setValue(val)
    },
    selected(val) {
      if (val === '' || val == null) {
        this.items = []
      }
    },
    search(val) {
      this.getItems(val)
    },
  },
  methods: {
    onChange() {
      let filtered = this.items.filter(it => it.id === this.select)
      let selected = filtered.length ? filtered[0] : null
      this.$emit('change', selected)
    },
    getItems(val) {
      this.isLoading = true
      if (val) {
        if (this.suggestType === companyTypes.ENTREPRENEUR) {
          this.getEntrepreneurSuggestions(val)
        } else if (this.suggestType === companyTypes.COMPANY) {
          this.getCompanySuggestions(val)
        } else {
          this.items = []
        }
      } else {
        this.items = []
      }
    },
    getCompanySuggestions(val) {
      companySuggestions(val).then(res => {
        this.fillItems(res)
      })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
    getEntrepreneurSuggestions(val) {
      entrepreneurSuggestions(val).then(res => {
        this.fillItems(res)
      })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
    fillItems(res) {
      this.items = res.map(item => {
        return {
          id: item.data.inn,
          name: item.value,
          label: `${item.value} (${item.data.inn})`
        }
      })
    },
    setValue(val) {
      this.getItems(val)
      this.select = val
    },
  }
}
</script>
