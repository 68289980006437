<template>
  <page-view :page-title="pageTitle">
    <section slot="content">
      <v-row class="mt-10">
        <v-col lg="8" md="6" offset-md="3" offset-lg="2">
          <validation-observer ref="form" v-slot="{  }">
            <form class="p-8 login-tabs-container">
              <v-row>
                <v-col lg="6">
                  <validation-provider vid="email" name="email" rules="required|email" v-slot="{ errors, classes }">
                    <v-text-field
                      outlined
                      :class="classes"
                      v-model="data.email"
                      :error-messages="errors"
                      label="email"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6">
                  <validation-provider vid="password" name="пароль" rules="required|min:8" v-slot="{ errors, classes }">
                    <v-text-field
                      outlined
                      v-model="data.password"
                      :error-messages="errors"
                      :class="classes"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      label="пароль"
                      @click:append="showPassword = !showPassword"
                      required
                    />
                  </validation-provider>
                </v-col>
                <v-col lg="6">
                  <validation-provider vid="password_confirmation" name="повторить пароль" rules="required|min:8|confirmed:password" v-slot="{ errors, classes }">
                    <v-text-field
                      outlined
                      v-model="data.password_confirmation"
                      :error-messages="errors"
                      :class="classes"
                      :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPasswordConfirmation ? 'text' : 'password'"
                      label="повторить пароль"
                      @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="6">
                  <validation-provider vid="type" name="" rules="required" v-slot="{ errors, classes }">
                    <v-select
                      :items="types"
                      outlined
                      item-text="name"
                      item-value="id"
                      v-model="data.type"
                      :error-messages="errors"
                      :class="classes"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <validation-provider v-if="data.type !== companyTypes.PERSON" vid="organization" name="ваша фирма"
                                   rules="required" v-slot="{ errors, classes }">
                <cd-company-suggest
                  :class="classes"
                  :suggest-type="data.type"
                  :error-messages="errors"
                  v-model="data.tax_id"
                  label="ваша фирма"
                  required
                  @change="setOrganization"
                ></cd-company-suggest>
              </validation-provider>


              <v-row v-if="data.type === companyTypes.PERSON">
                <v-col lg="6">
                  <validation-provider vid="tax_id" name="ИНН" rules="required|inn" v-slot="{ errors, classes }">
                    <v-text-field
                      outlined
                      :class="classes"
                      v-model="data.tax_id"
                      :error-messages="errors"
                      label="ИНН"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col lg="6">
                  <validation-provider vid="organization" name="ФИО"
                                       rules="required" v-slot="{ errors, classes }">
                    <v-text-field
                      outlined
                      :class="classes"
                      v-model="data.organization"
                      :error-messages="errors"
                      label="ФИО"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <div class="d-flex mb-5 align-center">
                <v-btn text class="mr-5 v-btn--primary" @click="validate" :loading="loading" :disabled="loading">
                  зарегистрироваться
                </v-btn>
                <div class="text-gray text-body-2">
                  нажимая "зарегистрироваться", вы подтверждаете согласие на <a href="/files/personal_data.pdf"
                                                                                target="blank">обработку
                  персональных данных</a>,
                  <a href="/" target="blank">политику конфиденциальности</a>, <a href="/" target="blank">правила
                  работы</a> и <a href="/" target="blank">лицензионный договор</a>.
                </div>
              </div>
              <div class="mb-5 text-danger" v-if="error">{{ error }}</div>
            </form>
          </validation-observer>
        </v-col>
      </v-row>
    </section>
  </page-view>
</template>

<script>
import "@/mixins/validation/innValidator"
import PageView from "@/layouts/components/PageView"
import {companyTypes} from "@/config/constants"
import CompanySuggestComponent from "@/components/form/CompanySuggest"

export default {
  name: 'SignInPage',
  components: {
    'page-view': PageView,
    'cd-company-suggest': CompanySuggestComponent
  },
  data() {
    return {
      data: {
        email: "",
        password: "",
        password_confirmation: "",
        type: companyTypes.COMPANY,
        tax_id: "",
        organization: "",
        recaptcha_token: null
      },
      error: null,
      showPassword: false,
      showPasswordConfirmation: false,
      loading: false,
      types: [
        {
          id: companyTypes.COMPANY,
          name: "я представляю организацию"
        },
        {
          id: companyTypes.ENTREPRENEUR,
          name: "я - индивидуальный предприниматель"
        },
        {
          id: companyTypes.PERSON,
          name: "я - самозанятый"
        },
      ],
      companyTypes: companyTypes
    }
  },
  computed: {
    pageTitle() {
      return `вы грузовладелец или экспедитор? протестируйте бесплатно с полным доступом на ${this.$store.getters.FREE_PERIOD_AFTER_SIGNUP} дней`
    }
  },
  async mounted() {
    await this.$recaptchaLoaded()
  },
  watch: {
    "data.type": {
      handler() {
        this.data.organization = null
        this.data.tax_id = null
      }
    },
  },
  methods: {
    async signup() {
      this.loading = true
      this.data.recaptcha_token = await this.$recaptcha("signup")
      this.$store.dispatch('signup', this.data).then(() => {
        this.error = null
        this.$router.push({path: '/pads'})
        this.loading = false
      }).catch((err) => {
        if (err.code === 422 && err.errors) {
          this.$refs.form.setErrors(err.errors);
          if (Object.prototype.hasOwnProperty.call(err.errors, 'error')) {
            this.error = err.errors.error[0]
          }
        } else if (err.message) {
          this.error = err.message
        }
        this.loading = false
      })
    },
    setOrganization(res) {
      if (res) {
        this.data.organization = res.name
        this.data.tax_id = res.id
      } else {
        this.data.organization = null
        this.data.tax_id = null
      }
    },
    validate() {
      console.log(this.data)
      this.loading = true
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false
          return
        }
        this.signup()
      })

    }
  }
}
</script>
