import { extend } from 'vee-validate'

extend('inn', inn => {

  if (typeof inn === 'number') {
    inn = inn.toString()
  } else if (typeof inn !== 'string') {
    inn = ''
  }
  if (/[^0-9]/.test(inn)) {
    return 'ИНН может состоять только из цифр'
  } else if ([12].indexOf(inn.length) === -1) {
    return 'ИНН может состоять только из 12 цифр'
  } else {
    let checkDigit = function (inn, coefficients) {
      let n = 0
      for (let i in coefficients) {
        n += coefficients[i] * inn[i]
      }
      return parseInt(n % 11 % 10)
    }

    let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
    let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
    if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
      return true
    } else {
      return 'недействительный ИНН'
    }
  }
})
