var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-view',{attrs:{"page-title":_vm.pageTitle}},[_c('section',{attrs:{"slot":"content"},slot:"content"},[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"lg":"8","md":"6","offset-md":"3","offset-lg":"2"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{staticClass:"p-8 login-tabs-container"},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":"email","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"outlined":"","error-messages":errors,"label":"email","required":""},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":"password","name":"пароль","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"outlined":"","error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":"пароль","required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":"password_confirmation","name":"повторить пароль","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"outlined":"","error-messages":errors,"append-icon":_vm.showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPasswordConfirmation ? 'text' : 'password',"label":"повторить пароль","required":""},on:{"click:append":function($event){_vm.showPasswordConfirmation = !_vm.showPasswordConfirmation}},model:{value:(_vm.data.password_confirmation),callback:function ($$v) {_vm.$set(_vm.data, "password_confirmation", $$v)},expression:"data.password_confirmation"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":"type","name":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"items":_vm.types,"outlined":"","item-text":"name","item-value":"id","error-messages":errors,"required":""},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}})]}}],null,true)})],1)],1),(_vm.data.type !== _vm.companyTypes.PERSON)?_c('validation-provider',{attrs:{"vid":"organization","name":"ваша фирма","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('cd-company-suggest',{class:classes,attrs:{"suggest-type":_vm.data.type,"error-messages":errors,"label":"ваша фирма","required":""},on:{"change":_vm.setOrganization},model:{value:(_vm.data.tax_id),callback:function ($$v) {_vm.$set(_vm.data, "tax_id", $$v)},expression:"data.tax_id"}})]}}],null,true)}):_vm._e(),(_vm.data.type === _vm.companyTypes.PERSON)?_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":"tax_id","name":"ИНН","rules":"required|inn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"outlined":"","error-messages":errors,"label":"ИНН","required":""},model:{value:(_vm.data.tax_id),callback:function ($$v) {_vm.$set(_vm.data, "tax_id", $$v)},expression:"data.tax_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"vid":"organization","name":"ФИО","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"outlined":"","error-messages":errors,"label":"ФИО","required":""},model:{value:(_vm.data.organization),callback:function ($$v) {_vm.$set(_vm.data, "organization", $$v)},expression:"data.organization"}})]}}],null,true)})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex mb-5 align-center"},[_c('v-btn',{staticClass:"mr-5 v-btn--primary",attrs:{"text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.validate}},[_vm._v(" зарегистрироваться ")]),_c('div',{staticClass:"text-gray text-body-2"},[_vm._v(" нажимая \"зарегистрироваться\", вы подтверждаете согласие на "),_c('a',{attrs:{"href":"/files/personal_data.pdf","target":"blank"}},[_vm._v("обработку персональных данных")]),_vm._v(", "),_c('a',{attrs:{"href":"/","target":"blank"}},[_vm._v("политику конфиденциальности")]),_vm._v(", "),_c('a',{attrs:{"href":"/","target":"blank"}},[_vm._v("правила работы")]),_vm._v(" и "),_c('a',{attrs:{"href":"/","target":"blank"}},[_vm._v("лицензионный договор")]),_vm._v(". ")])],1),(_vm.error)?_c('div',{staticClass:"mb-5 text-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }